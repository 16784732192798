<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <div v-if="exibirTabela">
        <DataTable
          :headers="headers"
          :endpoint="endpoint"
          :autoSearch="false"
          sortBy="numpedido"
          :sortDesc="false"
          ref="datatable"
          pdf
          csv
          excel
        >
          <template v-slot:statuspedido="{ col }">
            {{ statusLabel[col] }}
          </template>
          <template v-slot:totalpedido="{ col }">
            R$ {{ col | formatMoney }}
          </template>
          <template v-slot:totaldescontocupomsuperon="{ col }">
            R$ {{ col | formatMoney }}
          </template>
          <template v-slot:valorcomissao="{ col }">
            R$ {{ col | formatMoney }}
          </template>
          <template v-slot:detalhes="{ row }">
            <FormButton icon @click="() => onClickPedido(row)">
              <v-icon color="primario">fa-info-circle</v-icon>
            </FormButton>
          </template>
        </DataTable>
      </div>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Panel from '@/components/Panel'
import { mapGetters } from 'vuex'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import FormButton from '@/components/form/FormButton'
import { MENU_ALT_TITLE } from '@/store/actions/menu'

export default {
  name: 'PainelFaturas',
  components: {
    DataTable,
    Panel,
    MarketingModules,
    FormButton
  },
  data: () => ({
    headers: [
      { text: 'Pedido', clickable: false, value: 'numpedido', align: 'center' },
      {
        text: 'Data',
        clickable: false,
        value: 'datapedidochar',
        align: 'center'
      },
      {
        text: 'Total em compras',
        clickable: false,
        value: 'totalpedido',
        align: 'center'
      },
      {
        text: 'Valor de repasse',
        clickable: false,
        value: 'valorcomissao',
        align: 'center'
      },
      {
        text: 'Status pedido',
        clickable: false,
        value: 'statuspedido',
        align: 'center'
      },
      {
        text: 'Avaliação',
        clickable: false,
        value: 'avaliacao',
        align: 'center'
      },
      { text: 'Detalhes', clickable: false, value: 'detalhes', align: 'center' }
    ],
    modulosForm: [ModulesEnum.TODOS],
    statusLabel: {
      ORCAMENTO_NOVO: 'Orçamento novo',
      ORCAMENTO_PRECIFICADO: 'Orçamento precificado',
      ENVIADO: 'Novo pedido',
      SEPARANDO: 'Em separação',
      AGUARDANDOPAGAMENTO: 'Aguardando pagamento',
      PAGO: 'Pronto para entrega',
      PRONTO_RETIRADA: 'Pronto para retirada',
      EMENTREGA: 'Em entrega',
      ENTREGUE: 'Entregue',
      CANCELADO: 'Cancelado'
    }
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getEmpresaId', 'moduloFornecedorAtivo']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    exibirTabela() {
      return this.headers.length !== 0
    },
    endpoint() {
      return `/api/v2/fatura/fornecedor/${this.getFornecedorId}/fatura/${this.$route.params.idfatura}`
    }
  },
  methods: {
    onClickPedido(pedido) {
      return this.$router.push({
        path: `/fornecedor/pedidos/pedido?numped=${pedido.numpedido}`
      })
    }
  },
  mounted() {
    const fatura =
      this.$route.query.numfatura != 0 ? this.$route.query.numfatura : 'Próxima'
    this.$store.dispatch(MENU_ALT_TITLE, `Detalhes da fatura - ${fatura}`)
  }
}
</script>
