var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"}},[(_vm.exibirTabela)?_c('div',[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"autoSearch":false,"sortBy":"numpedido","sortDesc":false,"pdf":"","csv":"","excel":""},scopedSlots:_vm._u([{key:"statuspedido",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.statusLabel[col])+" ")]}},{key:"totalpedido",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatMoney")(col))+" ")]}},{key:"totaldescontocupomsuperon",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatMoney")(col))+" ")]}},{key:"valorcomissao",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(_vm._f("formatMoney")(col))+" ")]}},{key:"detalhes",fn:function(ref){
var row = ref.row;
return [_c('FormButton',{attrs:{"icon":""},on:{"click":function () { return _vm.onClickPedido(row); }}},[_c('v-icon',{attrs:{"color":"primario"}},[_vm._v("fa-info-circle")])],1)]}}],null,false,3024938571)})],1):_vm._e()]),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view"})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }